import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import * as firebase from 'firebase';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
//import { AdmobFreeService } from './service/admobfree.service.ts.service';
//import { AdMobFree } from '@ionic-native/admob-free/ngx';


firebase.initializeApp({
  apiKey: "AIzaSyBZwAEwxDJ-vof4pC5QJqYQe9V8zr47Sh8",
  authDomain: "aoth-410fd.firebaseapp.com",
  projectId: "aoth-410fd",
  storageBucket: "aoth-410fd.appspot.com",
  messagingSenderId: "291045805654",
  appId: "1:291045805654:web:963aef2d406d5a7f4e75f7",
  measurementId: "G-YSZF0BTSQB"
});
firebase.analytics();


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

