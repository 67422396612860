import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

declare var StartAppAds: any;
declare var cordova: any;
var options={
  bannerid:"507536146959461_507536426959433"
  ,isTesting:false
  };

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if(this.platform.is('android')) {
        //StartAppAds.init("201305236");
        cordova.plugins.codeplayfacebookads.showBannerAds(options,success,fail);
 
        function success(result)
        {
        console.log(result);
        }
        function fail(result)
        {
        console.log(result);
        } 
      }
    });

    //this.platform.ready().then(() => {
    //});

  }
}
